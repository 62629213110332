import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
// import { useLocation } from 'react-router-dom'
// import { BodyHead, BodyTitle } from '../../components/bakery/styleds'
import Row from '../../components/Row'

import DetailCard from './components/DetailCard'
// import SupportCard from './components/SupportCard'
import SupportCard from './components/backup'
// import Taproot from './components/Taproot'

import { idoData } from '../../constants/ido'

// hooks
// import useIdoBlock from '../../hooks/ido/useIdoBlock'
// import useNodeBlock from '../../hooks/ido/useNodeBlock'
import { useActiveWeb3React } from '../../hooks'
import { IDOTYPE } from '../../constants/ido'
// import { IDTOKEN } from '../../constants/pet'

import { FnContext } from '../../contexts/fnCallback'
import { localStore } from 'utils/tools'

const Wrapper = styled.div`
  padding: 72px 0;
  width: 100%;
  font-family: 'Baloo Da', 'Inter var', sans-serif;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 32px 16px;
  `}
`
const ParticipateRow = styled(Row)`
  position: relative;
  flex-direction: column;
  margin: auto;
  padding: 0 0 60px;
  width: 602px;
  height: auto;
  border-radius: 27px;
  overflow: hidden;
  background: ${({ theme }) => theme.bsBg27};
  box-shadow: ${({ theme }) =>
    !theme.darkMode ? '0px 2.4024312496185303px 12.012155532836914px 0px rgba(172, 85, 42, 0.16)' : 'none'};

  & > div {
    position: relative;
    padding: 0 38px;
    width: 100%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    & > div {
      padding: 0 24px;
    }
  `}
`

const options: any = [
  {
    symbol: 'ETH',
    name: 'eth',
    title: 'ETH',
    status: 0,
  },
]
export default function Participate() {
  const { account, chainId } = useActiveWeb3React()

  useEffect(() => {
    if (account) {
      localStore.set('Bakeryswap.account', account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const [detailSymbol, setDetailSymbol] = useState(
    !account || [97, 56].includes(chainId as number)
      ? options[0]
      : [1, 11155111].includes(chainId as number)
      ? options[0]
      : [1573].includes(chainId as number)
      ? options[0]
      : options[0]
  )

  const updateDetailSymbol = useCallback((value) => {
    setDetailSymbol(value)
  }, [])

  // const location = useLocation()
  // useEffect(() => {
  //   if (location.pathname === '/launchpad') {
  //     window.location.href = window.location.origin + '/#/swap'
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])

  return (
    <Wrapper>
      <FnContext.Provider value={updateDetailSymbol}>
        <ParticipateRow>
          <DetailCard item={idoData[8]} startNum={0} curNum={0} detailSymbol={detailSymbol}></DetailCard>

          <SupportCard
            type={IDOTYPE.BAKERY}
            options={options}
            dataInfo={idoData[8]}
            defaultSymbol={detailSymbol}
            platform={'bakery'}
          ></SupportCard>
        </ParticipateRow>
      </FnContext.Provider>
    </Wrapper>
  )
}
