import React, { useContext } from 'react'
import styled, { ThemeContext, css } from 'styled-components'
import IdoStatus from './IdoStatus'
// import Linkmenu from './Linkmenu'
import Row, { RowBetween } from '../../../components/Row'
import { TYPE, ExternalLink } from '../../../theme'
import { IdoItemProps } from '../../../constants/ido'
import { isMobile } from 'react-device-detect'
import { IDOTYPE } from 'constants/ido'

const ParticipateCard = styled.div`
  margin: 12px 0 0;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 7px 0 0;
  `}
`
const WebsiteRow = styled(Row)<{ pad?: string }>`
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ pad }) => (pad ? pad : '0')};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 23px;
    align-items: center;
    padding: 0;
  `}
`
const WebsiteImg = styled.div<{ isBg?: boolean }>`
  width: 180px;
  height: 180px;
  background-image: ${({ isBg }) => (isBg ? 'url(/images/coin-bg.png)' : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  img {
    width: ${({ isBg }) => (isBg ? '122px' : '100%')};
    height: ${({ isBg }) => (isBg ? '122px' : '100%')};
    border-radius: 14px;
  }
  ${({ theme, isBg }) => theme.mediaWidth.upToSmall`
    width: 294px;
    height: 294px;
    img {
      width: 236px;
      height: 236px;
      ${
        !isBg &&
        css`
          width: 100%;
          height: 100%;
        `
      }
    }
  `}
`
const CardImg = styled.img`
  width: 100%;
  height: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 108px;
  `}
`
const Website = styled.a`
  margin-top: 24px;
  width: 136px;
  line-height: 40px;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  background: ${({ theme }) => theme.bsBg4};
`
const WebsiteM = styled(Website)`
  width: 142px;
  margin-top: 0;
  line-height: 29px;
  border-radius: 6px;
  background: #428ef1;
  color: ${({ theme }) => theme.btnText1};
  font-family: Roboto-bold;
  font-weight: bold;
  font-size: 14px;
  img {
    margin-right: 8px;
    width: 14px;
    vertical-align: middle;
  }
`
const IdoTitle = styled.div`
  font-family: Baloo Da;
  font-size: 26px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.bsText14};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 26px;
  `}
`
const LinkWrapper = styled(Row)`
  width: auto;
  grid-column-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
  `}
`
const LinkBtn = styled(ExternalLink)`
  padding: 0px 5px;
  width: 88px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme.darkMode ? '#722F0D' : '#FFFDFA')};
  text-decoration: none !important;
  font-size: 12px;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  font-weight: 700;
  letter-spacing: 0.01em;
  background-color: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#AC552A')};
  border-radius: 6px;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => (theme.darkMode ? '#AC552A' : '#AC552A')};
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 28px;
    height: 28px
  `}
`
const TextTitle = styled.span<{ left?: string }>`
  margin-left: ${({ left }) => (left ? left : '7px')};
  vertical-align: middle;
`
const IconNav = styled.img<{ width?: string }>`
  width: ${({ width }) => (width ? width : '14px')};
  vertical-align: middle;
`
const IdoColumn = styled(Row)`
  margin: 5px 0 0;
  flex-direction: column;
  grid-row-gap: 14px;
  width: 284px;
  flex-shrink: 0;
  line-height: 20px;
  letter-spacing: 0.01em;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 0;
    width: 100%;
    grid-row-gap: 19px;
  `}
`
const IdoName = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #c58560;
`
const IdoAmount = styled.span`
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 15px;
  font-weight: 400;
  flex-shrink: 0;
  color: ${({ theme }) => theme.bsText14};
`
const ParticipateBetween = styled(RowBetween)<{ pb?: string; whitelist?: boolean; borderTop?: boolean }>`
  align-items: flex-start;
  padding-bottom: ${({ pb }) => (pb ? pb : '40px')};
  /* border-bottom: 1px solid ${({ theme }) => theme.bg3}; */
  /* border-bottom: ${({ borderTop }) => (borderTop ? '1px' : '0px')} solid ${({ theme }) => theme.bsBg45}; */
  ${({ theme, pb }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid ${theme.bsBg45};
    ${
      pb &&
      css`
        padding-bottom: ${pb};
      `
    }
  `}
`

const TitleColumn = styled(RowBetween)`
  margin: 13px 0 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 10px 0 0
  `}
`

interface IdoDataProps {
  item: IdoItemProps
  startNum?: number
  curNum?: number
  detailSymbol?: any
  updateIdoStatus?: boolean | number
}

export default function DetailCard({ item, startNum, curNum, detailSymbol, updateIdoStatus }: IdoDataProps) {
  const theme = useContext(ThemeContext)

  const isReadMore = true

  return (
    <>
      <CardImg
        src={isMobile ? (item?.detailImg_phone ? item?.detailImg_phone : item.detailImg) : item.detailImg}
        alt=""
      />
      {curNum && curNum !== 0 && startNum ? (
        curNum < startNum ? (
          <IdoStatus status={'Counting Down'} />
        ) : (
          <IdoStatus status={updateIdoStatus ? 'Ended' : 'Ongoing'} />
        )
      ) : (
        <></>
      )}
      {isReadMore ? (
        <TitleColumn>
          <IdoTitle>{item.title}</IdoTitle>
          {![
            'Bitcoin Cats - $1Cat',
            'BitLand-BLLB',
            'Bitcat-BICA',
            'BendDAO-BDIN',
            'Bitcoin Cats NFT',
            'OpenSky',
            'BitcoinVM',
            'Big Pump',
            '? ? ?',
          ].includes(item.title) ? (
            <WebsiteM href={item.website} target="_blank">
              <img src={require('../../../assets/ido/foottable_m.png')} alt="" />
              Read More
            </WebsiteM>
          ) : (
            <LinkWrapper>
              {item?.links.map((d: any, index: number) => {
                return (
                  <LinkBtn href={d?.url} target="_blank" key={index}>
                    <IconNav
                      src={isMobile ? (d?.iconphone ? d?.iconphone : d?.icon) : d?.icon}
                      // width={
                      //   index === 0 ? (['BendDAO-BDIN'].includes(item.title) ? '18px' : isMobile ? '22px' : '12px') : ''
                      // }
                    />
                    {isMobile ? '' : <TextTitle left={index === 0 ? '4px' : ''}>{d?.name}</TextTitle>}
                  </LinkBtn>
                )
              })}
            </LinkWrapper>
          )}
        </TitleColumn>
      ) : (
        <></>
      )}
      <ParticipateCard>
        <ParticipateBetween
          align="flex-start"
          whitelist={item.whitelist}
          borderTop={[IDOTYPE.OPENSKY].includes(IDOTYPE.OPENSKY)}
        >
          <WebsiteRow style={{ flexDirection: 'column' }}>
            {item.imgWebsite ? (
              <WebsiteImg>
                <img src={item.imgWebsite} />
              </WebsiteImg>
            ) : (
              <>
                <TYPE.largeHeader color={theme.bsText7} fontWeight={'normal'}>
                  {item.title}
                </TYPE.largeHeader>
                <Website href={item.website} target="_blank">
                  <TYPE.subHeader color={theme.primaryText1}>{item.readMore || 'Website'} &gt;</TYPE.subHeader>
                </Website>
              </>
            )}
          </WebsiteRow>
          <IdoColumn>
            {item?.leftTexts?.map((d: any, index: number) => {
              return (
                <RowBetween key={index}>
                  <IdoName>{d?.key || !d?.isValue ? d?.key : d?.secondKey}</IdoName>
                  <IdoAmount color={theme.bsText7}>
                    {d?.isValue
                      ? d?.secondValue
                        ? d?.secondValue
                        : d.value[detailSymbol.symbol.toLowerCase()]
                      : startNum}
                  </IdoAmount>
                </RowBetween>
              )
            })}
          </IdoColumn>
        </ParticipateBetween>
      </ParticipateCard>
    </>
  )
}
