import React, { useCallback, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { BigNumber } from 'bignumber.js'

// components
import NumericalInput from '../../../components/NumericalInput'
import Divider from '../../../components/Divider'
import Select, { OptionItem } from '../../../components/Select'

// import { IDOTYPE } from '../../../constants/ido'
import { isMobile } from 'react-device-detect'
import Row from 'components/Row'

const ExchangeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const InputBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.bsBorder10};
  border-radius: 12px;
  color: ${({ theme }) => theme.bsText10};
  padding: 9px 28px 9px 28px;
  height: 54px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 54px;
  `}
`
const BaseTokenWrapper = styled(InputBox)`
  position: relative;
  width: 100%;
  .option {
    opacity: 0.3;
    cursor: auto;
  }
  input {
    font-family: Baloo Da, 'Inter var', sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.bsText7};

    ::placeholder {
      color: ${({ theme }) => theme.text4};
      opacity: 0.3;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    input {
      font-size: 18px;
    }
  `}
`
const Bridge = styled.div`
  color: ${({ theme }) => theme.bsText38};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 12px;
`}
`
const Balance = styled.div`
  color: ${({ theme }) => (theme.darkMode ? '#C58560' : '#C58560')};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    align-self: flex-start;
  `}
`
const Min = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.primary1};
  font-weight: 700;
  font-size: 15px;
  padding: 0;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  letter-spacing: 0.01em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`
const IconArrow = styled.img`
  margin: 16px 0;
`
const PetsTokenWrapper = styled(InputBox)`
  width: 100%;
`

export interface OptionProps {
  symbol: string
  name: string
  title: string
  status?: boolean
  min?: number
  max?: number
}

interface ExchangePetsProps {
  options: OptionProps[]
  baseAsset: OptionProps
  updateBaseAsset: (value: OptionProps) => void
  stakedNum?: BigNumber
  balance: BigNumber
  value: string | number
  updateValue: (value: string) => void
  petShow?: boolean
  allowanceNum?: number | string
  idoMax?: boolean
  idoMin?: boolean
  type?: string
}

export const MINBUSD = 30
export const MINPET = 1000
export default function ExchangePets({
  options,
  baseAsset,
  updateBaseAsset,
  stakedNum,
  balance,
  value,
  updateValue,
  petShow = true,
  allowanceNum,
  idoMax = false,
  idoMin = false,
}: ExchangePetsProps) {
  const theme = useContext(ThemeContext)
  const onSelect = useCallback(
    async (d) => {
      if (d.status) return
      if (baseAsset !== d.symbol) {
        updateBaseAsset(d)
      }
    },
    [baseAsset, updateBaseAsset]
  )

  const onIdoMin = useCallback(() => {
    // const symbol = baseAsset.symbol.toLowerCase()
    // let min = 100
    // switch (symbol) {
    //   case 'bakery':
    //     min = 1000
    //     break
    //   case 'doggycoin':
    //     min = 10000000
    //     break
    //   case 'eth':
    //     min = 0.1
    //     break
    //   case 'punkx':
    //     min = 1
    //     break
    // }
    const min = baseAsset?.min ? baseAsset?.min : 100
    updateValue(min.toString().replace(/,/g, '.'))
  }, [updateValue, baseAsset])

  const onMax = useCallback(() => {
    if (balance.toNumber() < 0) return
    if (baseAsset?.max) return updateValue(baseAsset?.max.toString())
    const a = allowanceNum ? allowanceNum : 0
    const b = +balance.toString()
    // const num = +a >= b ? new BigNumber(balance).toString() : new BigNumber(a).toString()
    const num = new BigNumber(balance).toString()
    updateValue(num)
  }, [balance, baseAsset, updateValue, allowanceNum])

  return (
    <>
      <ExchangeWrapper>
        {(stakedNum && stakedNum.gte(0)) || balance.gte(0) ? (
          <Row justify="flex-end" marginBottom="5px">
            {stakedNum && stakedNum.gte(0) && (
              <Bridge>Bridged: {stakedNum?.toFixed(2, BigNumber.ROUND_DOWN).toString()} $1CAT</Bridge>
            )}
            {balance.gte(0) && (
              <Balance>
                Balance: {idoMax ? balance?.toFixed(2, BigNumber.ROUND_DOWN).toString() : balance.toNumber()}{' '}
                {baseAsset.title}
              </Balance>
            )}
          </Row>
        ) : null}
        <BaseTokenWrapper>
          <NumericalInput fontSize={'22px'} value={value} onUserInput={updateValue} />
          {idoMin ? <Min onClick={onIdoMin}>MIN</Min> : ''}
          {idoMax ? (
            <Min style={{ marginLeft: 8 }} onClick={onMax}>
              MAX
            </Min>
          ) : (
            ''
          )}
          <Divider isHorizontal={false} height={'29px'} marginLeft={12} marginRight={13} />
          <Select selectedValue={baseAsset.title} height={30} top="52px" right="-28px" minWidth={90}>
            {options.map((d) => {
              return d.status ? (
                <OptionItem
                  pd="13px 12px 13px 24px"
                  className="option"
                  key={d.symbol}
                  onClick={() => {
                    onSelect(d)
                  }}
                >
                  {d.title}
                </OptionItem>
              ) : (
                <OptionItem
                  pd="13px 12px 13px 24px"
                  className={d.symbol === baseAsset.symbol ? 'CURSELEST' : ''}
                  key={d.symbol}
                  onMouseDown={() => {
                    onSelect(d)
                  }}
                  onClick={() => {
                    if (!isMobile) return
                    onSelect(d)
                  }}
                >
                  {d.title}
                  <img src={require('../../../assets/ido/selected.png').default} alt="" className="img-right" />
                </OptionItem>
              )
            })}
          </Select>
        </BaseTokenWrapper>
        {/* {balance.gte(0) && isMobile && (
          <Balance>
            Balance: {idoMax ? balance?.toFixed(2, BigNumber.ROUND_DOWN).toString() : balance.toNumber()}{' '}
            {baseAsset.title}
          </Balance>
        )} */}
        {petShow ? (
          <>
            <IconArrow src={`/images/ido/exchange_arrow${theme.darkMode ? '_light' : ''}.svg`} alt="icon" />
            <PetsTokenWrapper>
              <span style={{ fontWeight: 700 }}>PET</span>
            </PetsTokenWrapper>
          </>
        ) : (
          ''
        )}
      </ExchangeWrapper>
    </>
  )
}
